import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../../scss/homeAdmin.scss";

const SettingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <Row>
            <Col>
              <Link
                to={{
                  pathname: "/admin/setting/video",
                }}
              >
                <Button
                  variant="outline-primary"
                  className="w-75 text-home-admin"
                  style={{ height: 70, marginTop: "10%" }}
                >
                  วิดีโอ
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/setting/text",
                }}
              >
                <Button
                  variant="outline-success"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  WHAT IS ESCAPE ROOM
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/setting/photopromotion",
                }}
              >
                <Button
                  variant="outline-warning"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  PROMOTION
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link
                to={{
                  pathname: "/admin/setting/review",
                }}
              >
                <Button
                  variant="outline-danger"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  REVIEW US
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/setting/news",
                }}
              >
                <Button
                  variant="outline-info"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  NEWS
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/setting/franchise",
                }}
              >
                <Button
                  variant="outline-secondary"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  Team Building
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SettingPage;
