import Carousel from "react-bootstrap/Carousel";
import "../../scss/silder.scss";
import Image1 from "../../assets/slide/0.jpeg";
import Image2 from "../../assets/slide/1.jpg";
import Image3 from "../../assets/slide/2.jpg";
import Image4 from "../../assets/slide/3.jpg";
import Image5 from "../../assets/slide/4.jpg";
import Image6 from "../../assets/slide/5.jpg";
import Image7 from "../../assets/slide/6.jpg";
import Image8 from "../../assets/slide/7.jpg";

const Slider = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image1}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image2}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image4}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image5}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image6}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image7}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 high-image-slider"
          src={Image8}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
