import * as React from "react";
import "../../../scss/firstPage.scss";
import escaperooms from "../../../assets/images/escaperooms.png";
import clock from "../../../assets/images/clock.png";
import locationIcon from "../../../assets/images/locationIcon.svg";
import whatsapp from "../../../assets/images/WhatsApp.svg";
import line from "../../../assets/images/LINE.svg";
import telephoneIcon from "../../../assets/images/TelNumber.svg"
import map from "../../../assets/images/map.jpg";
import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import firstPage from "../../../api/firstPage";
import apiRoom from "../../../api/roomApi";

export function FirstPage() {
  const navigate = useNavigate();
  const [linkVideo, setLinkVideo] = useState("");
  const [text, setText] = useState("");
  const [franchise, setFranchise] = useState("");
  const [data, setData] = useState([]);
  const [dataInvestigation, setDataInvestigation] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    apiRoom
      .getAll()
      .then((r) =>
        r.data.elements
          ? setData(
              r.data.elements.filter((r) => r.categoryName === "escape room")
            )
          : setData([])
      );
  }, []);

  useEffect(() => {
    apiRoom
      .getAll()
      .then((r) =>
        r.data.elements
          ? setDataInvestigation(
              r.data.elements.filter((r) => r.categoryName === "Investigation")
            )
          : setDataInvestigation([])
      );
  }, []);

  const fetch = async () => {
    const resVideo = await firstPage.getVideo();
    setLinkVideo(resVideo?.data?.elements[0].link);
    const resText = await firstPage.getText();
    setText(resText?.data?.elements[0].text);
    const resNews = await firstPage.getNews();
    setDataNews(resNews.data.elements ? resNews.data.elements : []);
    const resFranchise = await firstPage.getFranchise();
    setFranchise(resFranchise.data?.elements[0]?.imageUrl);
    const resPromotion = await firstPage.getPhotoPromotion();
    setDataPromotion(
      resPromotion?.data?.elements?.filter((r) => r.status !== false)
    );
    const resReview = await firstPage.getReview();
    setDataReview(resReview?.data?.elements?.filter((r) => r.status !== false));
  };

  const openNewTab = (val) => {
    const newWindow = window.open(val, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const [dataPromotion, setDataPromotion] = React.useState([]);
  const [dataReview, setDataReview] = React.useState([]);
  const [dataNews, setDataNews] = React.useState([]);
  var settings = {
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: 3,
    autoplay: false,
    autoplaySpeed: 4000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="first-page">
      <section id="escaperooms">
        <div className="block-escaperooms">
          <div>
            <img
              className="img-escaperooms"
              src={escaperooms}
              alt="escaperooms"
            ></img>
          </div>

          <div className="block-media">
            <div className="block-video">
              {/* <iframe style={{ width: "100%", height: "100%", maxHeight: "469px", maxWidth: "820px", "border-radius": "15px" }} src="https://www.youtube.com/watch?v=tYPIR8XWXFE&ab_channel=GMMTVRECORDS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe> */}
              <ReactPlayer
                url={linkVideo}
                className="react-player video"
                playing={false}
                width="100%"
                height="100%"
              />
            </div>
            <div className="block-image">
              <div>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "462px",
                    maxHeight: "467px",
                  }}
                  src={clock}
                  alt="clock"
                ></img>
              </div>
            </div>
          </div>
          <div className="block-description">
            <div className="block-bg">
              <div className="container-description">
                <div className="container-title">
                  <div className="border-title">WHAT IS ESCAPE ROOM</div>
                </div>
                <div className="description">
                  <p>{text}</p>
                  {/*<p style={{ margin: 0 }}>*/}
                  {/*  The game is often designed to be challenging, and requires*/}
                  {/*  teamwork, communication, and creative problem solving in*/}
                  {/*  order to succeed. Escape rooms have become increasingly*/}
                  {/*  popular around the world as a fun and engaging way to spend*/}
                  {/*  time with friends or family, or as a team-building activity*/}
                  {/*  for businesses and organizations.*/}
                  {/*</p>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="block-booking">
            <div className="container-title">
              <div className="border-title">CHOOSE YOUR ESCAPE ROOM</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="container-booking-room">
                {data?.length > 0
                  ? data.map((loRow, index) => (
                      <div className="card-booking">
                        <img
                          className="image-booking"
                          src={loRow.imageUrl}
                          alt="escaperooms"
                        ></img>
                        {loRow.coming !== true ? (
                          <Link
                            to={{
                              pathname: "/selectqueue",
                              search: `?roomId=${loRow.id}`,
                            }}
                          >
                            <div className="container-button">
                              <div className="button-default position">
                                Book Now
                              </div>
                            </div>
                          </Link>
                        ) : null}
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div
              className="container-booking-room-mobile"
              style={{ margin: "0 -25px", paddingTop: "30px" }}
            >
              <Slider {...settings}>
                {data?.length > 0
                  ? data.map((loRow, index) => (
                      <div className="card-booking">
                        <img
                          className="image-booking"
                          src={loRow.imageUrl}
                          alt="escaperooms"
                        ></img>
                        {loRow.coming !== true ? (
                          <Link
                            to={{
                              pathname: "/selectqueue",
                              search: `?roomId=${loRow.id}`,
                            }}
                          >
                            <div className="container-button">
                              <div className="button-default position">
                                Book Now
                              </div>
                            </div>
                          </Link>
                        ) : null}
                      </div>
                    ))
                  : null}
              </Slider>
            </div>
          </div>
          <div className="block-booking">
            <div className="container-title">
              <div className="border-title">CHOOSE YOUR INVESTIGATION</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="container-booking-room">
                {dataInvestigation?.length > 0
                  ? dataInvestigation.map((loRow, index) => (
                      <>
                        <div className="card-booking">
                          <img
                            className="image-booking"
                            src={loRow.imageUrl}
                            alt="escaperooms"
                          ></img>
                          <Link
                            to={{
                              pathname: "/selectqueue",
                              search: `?roomId=${loRow.id}`,
                            }}
                          >
                            <div className="container-button">
                              <div className="button-default position">
                                Book Now
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    ))
                  : null}
              </div>
            </div>
            <div
              className="container-booking-room-mobile"
              style={{ margin: "0 -25px", paddingTop: "30px" }}
            >
              <Slider {...settings}>
                {dataInvestigation?.length > 0
                  ? dataInvestigation.map((loRow, index) => (
                      <div className="card-booking">
                        <img
                          className="image-booking"
                          src={loRow.imageUrl}
                          alt="escaperooms"
                        ></img>
                        <Link
                          to={{
                            pathname: "/selectqueue",
                            search: `?roomId=${loRow.id}`,
                          }}
                        >
                          <div className="container-button">
                            <div className="button-default position">
                              Book Now
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  : null}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section id="promotion">
        <div className="block-promotion">
          <div className="container-title">
            <div className="border-title">PROMOTION</div>
          </div>
          <div className="container-slide promotion">
            <Carousel controls={false} touch={true}>
              {dataPromotion?.length > 0
                ? dataPromotion.map((loRow, index) => (
                    <Carousel.Item>
                      <img
                        className="d-block size-promotion"
                        src={loRow?.imageUrlPhotoPromotion}
                        alt="First slide"
                      />
                      {/* <picture className="d-block size-promotion">
                      <source media='(max-width: 768px)' srcSet={loRow.img_mobile} />
                      <source media='(min-width: 768px)' srcSet={loRow.img} />
                      <img src={loRow.img} alt='promotion' />
                    </picture> */}
                    </Carousel.Item>
                  ))
                : null}
            </Carousel>
          </div>
        </div>
      </section>
      <section id="review">
        <div className="block-review-img">
          <div className="block-review">
            <div className="container-title">
              <div className="border-title">REVIEW US</div>
            </div>
            <div className="container-slide review">
              <Carousel touch={true}>
                {dataReview?.length > 0
                  ? dataReview.map((loRow, index) => (
                      <Carousel.Item>
                        {/* <img
                        className="d-block w-100 size-review"
                        src={loRow.img}
                        alt="First slide"
                      /> */}
                        <picture className="d-block size-review">
                          <source
                            media="(max-width: 1000px)"
                            srcSet={loRow?.imageUrlReview}
                          />
                          <source
                            media="(min-width: 1000px)"
                            srcSet={loRow?.imageUrlReview}
                          />
                          <img
                            style={{ width: "100%" }}
                            src={loRow.img}
                            alt="promotion"
                          />
                        </picture>
                      </Carousel.Item>
                    ))
                  : null}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="block-contact">
          <div className="container-title">
            <div className="border-title">CONTACT</div>
          </div>
          <div className="container-contact">
            <div className="address">
              <img
                className="icon-location"
                src={locationIcon}
                alt="locationIcon"
              ></img>
              <p className="text-address">
                1191 Phahonyothin rd., Phaya-Thai Bangkok, TH (BTS Aree Exit 1)
              </p>
            </div>
            <div className="map">
              <img
                style={{
                  width: "100%",
                  maxWidth: "1380px",
                  maxHeight: "667px",
                }}
                src={map}
                alt="map"
              ></img>
            </div>
            <div className="desc">
              <p>
                From BTS Ari Exit 1, walk 70 m. towards Victory Monument,
                entrance is a black tunnel on your right hand side (between EXIM
                Bank and ESSO)
              </p>
              <p>
                For TAXI: ไปธนาคารเพื่อการส่งออกและนำเข้า (EXIM แบงก์)
                ถ.พหลโยธินขาจากอนุสาวรีย์ชัยสมรภูมิไปสะพานควาย
                ส่งผู้โดยสารตรงอุโมงค์สีดำระหว่างปั้มเอสโซกับธนาคาร EXIM
              </p>
            </div>
            <div className="container-button">
              <div
                className="button-default social"
                onClick={(e) => openNewTab(`https://wa.me/66632544533`)}
              >
                <div style={{ marginRight: "10px" }}>
                  <img
                    className="icon-social whatapp"
                    src={whatsapp}
                    alt="whatsapp"
                  ></img>
                </div>
                whatsapp CHAT
              </div>
              <div
                className="button-default social"
                onClick={(e) => openNewTab(`https://lin.ee/hZLGx9d`)}
              >
                <div style={{ marginRight: "10px" }}>
                  <img className="icon-social" src={line} alt="line"></img>
                </div>
                Add line
              </div>
              <div className="button-default social" onClick={(e) => openNewTab(`tel:+66992893645`)}>
                <div style={{ marginRight: "10px" }}>
                  <img className="icon-social" src={telephoneIcon} alt="telephone icon"></img>
                </div>
                Call Us
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="news">
        <div className="block-news">
          <div className="container-title">
            <div className="border-title">NEWS</div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="container-news">
              {dataNews?.length > 0
                ? dataNews.map((loRow, index) => (
                    <>
                      <div className="card-news">
                        <img
                          className="image-news"
                          src={loRow?.imageUrl}
                          alt="escaperooms"
                        ></img>
                        <div className="description">
                          <p className="title p">{loRow?.title}</p>
                          <p className="text-desc">{loRow?.detail}</p>
                          <div className="block-date">
                            <p>{loRow?.date}</p>
                            <p
                              onClick={(e) => openNewTab(loRow?.link)}
                              className="readmore"
                            >
                              Read More
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                : null}
            </div>
          </div>
          <div className="container-news-mobile" style={{ margin: "0 -25px" }}>
            <Slider {...settings}>
              {dataNews?.length > 0
                ? dataNews.map((loRow, index) => (
                    <div className="card-news">
                      <img
                        className="image-news"
                        src={loRow?.imageUrl}
                        alt="escaperooms"
                      ></img>
                      <div className="description">
                        <p className="title p">{loRow?.title}</p>
                        <p className="text-desc">{loRow?.detail}</p>
                        <div className="block-date">
                          <p>{loRow?.date}</p>
                          <p
                            onClick={(e) => openNewTab(loRow?.link)}
                            className="readmore"
                          >
                            Read More
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </Slider>
          </div>
          {/* <div className="container-button">
            <div className="button-default">see more</div>
          </div> */}
        </div>
      </section>
      <section id="franchise">
        <div className="block-franchise">
          <div className="container-title">
            <div className="border-title">Team Building</div>
          </div>
          <div className="block-img-franchise">
            <img
              style={{ width: "100%", maxWidth: "1380px" }}
              src={franchise}
              alt="franchise"
            ></img>
            {/* <picture className="d-block w-100">
              <source media='(max-width: 768px)' srcSet={franchiseMobile} />
              <source media='(min-width: 768px)' srcSet={franchise} />
              <img src={franchise} alt='franchise' />
            </picture> */}
          </div>
        </div>
      </section>
    </div>
  );
}

