import * as React from "react";
import Slider from "../../../components/slider/slider";
import "../../../scss/home.scss";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import api from "../../../api/queueApi";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";

export function Payment() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const invoiceNo = searchParams.get("invoiceNo");
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [invoiceNo]);

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const fetchData = async () => {
    if (invoiceNo !== null) {
      const resData = await api.getAll();
      if (resData) {
        alertSweet(
          "Succeed",
          "Please screenshot the details to confirm with the shop.",
          "success"
        );
        const dataFilter = resData.data.elements.filter(
          (r) => r.invoiceNo === invoiceNo
        );
        setData(dataFilter);
        await api.update({
          ...dataFilter[0],
          statusPayment: 1,
        });
      }
    } else {
      navigate(`/`);
    }
  };
  return (
    <div>
      <Container>
        <Card style={{ width: "50%", margin: "auto" }} className="mt-5">
          <Card.Header
            style={
              invoiceNo === "null"
                ? { backgroundColor: "red" }
                : { backgroundColor: "#31e031" }
            }
          >
            {invoiceNo === "null" ? "Failed payment" : "Successful payment"}
          </Card.Header>
          <Card.Body>
            {data.length > 0
              ? data.map((r) => (
                  <div className="text-left mt-3">
                    <Row>
                      <Col>
                        <Card.Text>Name: {r.firstName}</Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>Last Name: {r.lastName}</Card.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card.Text>Nickname: {r.nickName}</Card.Text>
                      </Col>
                      <Col>
                        <Card.Text>Phone: {r.tel}</Card.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card.Text>Booking Date: {r.dateTime}</Card.Text>
                      </Col>
                      <Col>
                        Booking Time:{" "}
                        {r.dateTimeDTOList.length > 0
                          ? r.dateTimeDTOList
                              .sort((a, b) => (a.time > b.time ? 1 : -1))
                              .map((r) => r.dateTimeName + ",")
                          : "do not have"}{" "}
                        น.
                      </Col>
                    </Row>
                    <label
                      style={{ color: "red", fontSize: 14 }}
                      className="mt-1"
                    >
                      ***Please inform the details to the staff according to the
                      reservation information.
                    </label>
                  </div>
                ))
              : null}
            <Link
              to={{
                pathname: "/",
              }}
            >
              <Button variant="primary" className="mt-3">
                back to main page
              </Button>
            </Link>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
