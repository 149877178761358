import http from "./http-common";

const path = "/offEvery";

const getAll = (pageNumber, perPage) =>
  http.get(path, {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });

const create = (data) => http.post(`${path}/create`, data);
const update = (data) => http.put(`${path}/update`, data);
export default {
  getAll,
  create,
  update,
};
