import React, { useEffect, useState } from "react";
import { Card, Container, Button, Col, Row, Form } from "react-bootstrap";
import "../../../../../scss/roomList.scss";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import OffEveryApi from "../../../../../api/OffEveryApi";
import Swal from "sweetalert2";
import firstPage from "../../../../../api/firstPage";
import { Box, makeStyles, Paper } from "@material-ui/core";
import image from "../../../../../assets/icon/upload2.png";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const useStyle = makeStyles({
  image: {
    borderRadius: 0,
  },
});

const Pro6Page = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [type, setType] = useState("edit");
  const [oldImg, setOldImg] = useState("");
  const [status, setStatus] = useState(true);
  const [state, setstate] = useState({
    file: "",
    imagePreviewUrl: "",
  });
  const classes = useStyle();
  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const res = await firstPage.getPhotoPromotion();
    if (res?.data?.elements) {
      const data = res.data.elements.filter((r) => r.name === "รูปที่ 6");
      if (data.length > 0) {
        setOldImg(data[0].imageUrlPhotoPromotion);
        setId(data[0].id);
        setStatus(data[0].status);
      } else {
        setType("create");
      }
    } else {
      setType("create");
    }
  };
  const onSubmit = async () => {
    const data = new FormData();
    if (type === "edit") {
      data.append("id", id);
    }
    if (state.file !== "") {
      data.append("filePhotoPromotion", state.file);
      data.append(
        "fileNamePhotoPromotion",
        state.file.name ? state.file.name : ""
      );
      data.append("imageUrlPhotoPromotion", state.imagePreviewUrl);
    }
    data.append("name", "รูปที่ 6");
    data.append("status", status);
    if (type === "edit") {
      const res = await firstPage.updatePhotoPromotion(data);
      if (res) {
        alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
      }
    } else {
      const res = await firstPage.createPhotoPromotion(data);
      if (res) {
        alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
      }
    }
  };
  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const uploadSingleFile = (e) => {
    setstate({
      imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const onCheckStatus = (status) => {
    if (status === 200) {
      fetch();
      alertSweet("สำเร็จ", "ลบรายการสำเร็จ", "success");
    } else {
      alertSweet("ไม่สำเร็จ", "ลบรายการไม่สำเร็จ", "error");
    }
  };

  const onDelete = async () => {
    try {
      await firstPage.removePhotoPromotion(id);
      onCheckStatus(200);
      fetch();
    } catch (error) {
      onCheckStatus(404);
    }
  };

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <h3 className="mb-0 text-left">รูปที่ 6</h3>
              <br />
              <Row>
                <Paper
                  style={{
                    width: "250px",
                    margin: "auto",
                    // marginTop: "10%",
                  }}
                >
                  <Box
                    width="250px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      id="file"
                      onChange={uploadSingleFile}
                      style={{ display: "none" }}
                    />
                    <p className="mt-1 mb-0"> เพิ่มรูปภาพ </p>

                    <img
                      src={
                        state.imagePreviewUrl
                          ? state.imagePreviewUrl
                          : type === "edit"
                          ? oldImg
                          : image
                      }
                      className={classes.image}
                      id="output"
                      width="200"
                      alt="test"
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    padding="10px 20px"
                  >
                    <label htmlFor="file" style={{ cursor: "pointer" }}>
                      <PhotoCamera />
                    </label>
                  </Box>
                </Paper>
                <br />
                <div className="mt-2">
                  <Form.Label>การใช้งาน</Form.Label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    style={{ textAlign: "center" }}
                    onClick={() => setStatus(!status)}
                    checked={status}
                  />
                </div>
              </Row>
              <Row></Row>
              <div className="p-2">
                <Button
                  className="m-2"
                  style={{ width: "40%" }}
                  onClick={() => onSubmit()}
                  // onClick={() => handleShow()}
                  // disabled={time.length === 0}
                >
                  ยืนยัน
                </Button>
                {/*<Button*/}
                {/*  className="m-2"*/}
                {/*  variant={status ? "outline-danger" : "outline-success"}*/}
                {/*  style={{ width: "40%" }}*/}
                {/*  onClick={() => onDelete()}*/}
                {/*>*/}
                {/*  {status ? "ไม่ใช้งาน" : "ใช้งาน"}*/}
                {/*</Button>*/}
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Pro6Page;
