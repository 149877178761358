// import axios from "axios";
//
// export default axios.create({
//   baseURL: "https://api.labor-program.com/api",
//   headers: {
//     "Content-type": "application/json",
//     Authorization: `Bearer ${
//       typeof window !== "undefined" ? localStorage.getItem("access-token") : ""
//     }`,
//   },
// });

import axios from 'axios';

export default axios.create({
    baseURL: '/api',
    // baseURL: 'http://localhost:8093/api',
    // baseURL: 'https://api.lastprisonescaperoombkk.com/api',
    headers: {
        'Content-type': 'application/json',
        // 'header': 'Access-Control-Allow-Headers: X-Requested-With, privatekey',
        Authorization: `Bearer ${localStorage.getItem('access-token')}`,
    },
});
