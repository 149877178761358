import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import "../../../../../scss/roomList.scss";
import Rev1Page from "./rev1";
import Rev2Page from "./rev2";
import Rev3Page from "./rev3";
import Rev4Page from "./rev4";
import Rev5Page from "./rev5";
import Rev6Page from "./rev6";

const ReviewPage = () => {
  const [review, setReview] = useState("1");
  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <Form.Select className="mt-2" onChange={(e) => setReview(e.target.value)}>
            <option value="1">รูปที่ 1</option>
            <option value="2">รูปที่ 2</option>
            <option value="3">รูปที่ 3</option>
            <option value="4">รูปที่ 4</option>
            <option value="5">รูปที่ 5</option>
            <option value="6">รูปที่ 6</option>
          </Form.Select>
          {review === "1" ? (
            <Rev1Page />
          ) : review === "2" ? (
            <Rev2Page />
          ) : review === "3" ? (
            <Rev3Page />
          ) : review === "4" ? (
            <Rev4Page />
          ) : review === "5" ? (
            <Rev5Page />
          ) : (
            <Rev6Page />
          )}
        </div>
      </Container>
    </div>
  );
};

export default ReviewPage;
