import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./contrianer/view/home";
import NavBar from "./components/navbar/nav";
import { Queue } from "./contrianer/view/selectQueue";
import { Payment } from "./contrianer/view/paymentSuccess";
import DayOff from "./contrianer/view/admin/dayOff/dayOff";
import DayOffList from "./contrianer/view/admin/dayOff/dayOffList";
import Login from "./contrianer/view/admin/login/login";
import HomeAdmin from "./contrianer/view/admin/home/home";
import RoomList from "./contrianer/view/admin/room/roomList";
import RoomEdit from "./contrianer/view/admin/room/roomEdit";
import QueueList from "./contrianer/view/admin/queue/queueList";
import OffEvery from "./contrianer/view/admin/offEvery";
import Promotion from "./contrianer/view/admin/promotion/promotionList";
import PromotionAdd from "./contrianer/view/admin/promotion/promotionAdd";
import Setting from "./contrianer/view/admin/settingWeb/setting";
import Video from "./contrianer/view/admin/settingWeb/video";
import Franchise from "./contrianer/view/admin/settingWeb/franchise";
import News from "./contrianer/view/admin/settingWeb/news/news";
import NewsEdit from "./contrianer/view/admin/settingWeb/news/newsEdit";
import "./scss/global.scss";
import { FirstPage } from "./contrianer/view/firstPage/firstPage";
import NavBarHome from "./components/navbar/navHome";
import TextPage from "./contrianer/view/admin/settingWeb/escaperoomText";
import ReviewPage from "./contrianer/view/admin/settingWeb/review/review";
import PhotoPromotionPage from "./contrianer/view/admin/settingWeb/photopromotion/photopromotion";

var style = {
  textAlign: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
  backgroundColor: "#000",
  color: "#fff",
  marginTop: 30,
};

var phantom = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {window.location.href !== "https://www.escaperoombangkok.com/" &&
        window.location.href !== "http://localhost:3000/" ? (
          <NavBarHome location={window.location.href} />
        ) : (
          <NavBar location={window.location.href} />
        )}
        <Routes>
          <Route path="/">
            <Route index element={<FirstPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="selectqueue" element={<Queue />} />
            <Route path="payment" element={<Payment />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/home" element={<HomeAdmin />} />
            <Route path="/admin/roomlist" element={<RoomList />} />
            <Route path="/admin/roomedit" element={<RoomEdit />} />
            <Route path="/admin/queuelist" element={<QueueList />} />
            <Route path="/admin/dayofflist" element={<DayOffList />} />
            <Route path="/admin/dayoff" element={<DayOff />} />
            <Route path="/admin/offevery" element={<OffEvery />} />
            <Route path="/admin/promotion" element={<Promotion />} />
            <Route path="/admin/promotionadd" element={<PromotionAdd />} />
            <Route path="/admin/setting" element={<Setting />} />
            <Route path="/admin/setting/video" element={<Video />} />
            <Route path="/admin/setting/franchise" element={<Franchise />} />
            <Route path="/admin/setting/news" element={<News />} />
            <Route path="/admin/setting/news/edit" element={<NewsEdit />} />
            <Route path="/admin/setting/text" element={<TextPage />} />
            <Route path="/admin/setting/review" element={<ReviewPage />} />
            <Route
              path="/admin/setting/photopromotion"
              element={<PhotoPromotionPage />}
            />
          </Route>
        </Routes>
        {window.location.href === "https://www.escaperoombangkok.com/home" ||
        window.location.href === "http://localhost:3000/home" ? (
          <div>
            <div style={phantom} />
            <div
              className="text-center p-4"
              // style={{
              //   backgroundColor: "#000",
              //   color: "#fff",
              //   marginTop: 30,
              // }}
              style={style}
            >
              <p style={{ marginBottom: 0 }}>
                Line ID : @569ldfuv โทร : 063-324-4519 หรือ 099 289-3645 Email :
                info.kidmai@gmail.com ที่อยู่ : 1191 ถนนพหลโยธิน แขวงพญาไท
                เขตพญาไท กรุงเทพมหานคร 10400 ประเทศไทย
              </p>
            </div>
          </div>
        ) : null}
      </BrowserRouter>
    </div>
  );
}

export default App;
