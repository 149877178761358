import * as React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "../../../scss/queue.scss";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import ModalCheck from "../../../components/modal/modalCheck";
import api from "../../../api/selectQueueApi";
import apiRoom from "../../../api/roomApi";
import apiBook from "../../../api/queueApi";
import apiPromotion from "../../../api/promotion";
import Swal from "sweetalert2";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import dayOffApi from "../../../api/dayOffApi";
import offEveryApi from "../../../api/OffEveryApi";
import lastWarnning from "../../../assets/lastWarnning.png";
export function Queue() {
  const sign = require("jwt-encode");
  const secret =
    "223CFB02A0018C6C13BAD2A118B4A8905390EB1797F8F05B6622C83C65657178";
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const paymentResponse = searchParams.get("paymentResponse");
  const merchantID = searchParams.get("merchantID");
  const invoiceNo = searchParams.get("invoiceNo");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState([]);
  const [timeShow, setTimeShow] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [booking, setBooking] = useState([]);
  const [options, setOptions] = useState([]);
  const [amount, setAmount] = useState(0);
  const [peopleAmount, setPeopleAmount] = useState([]);
  const [dayOff, setDayOff] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [everyOff, setEveryOff] = useState("");

  useEffect(() => {
    setTime([]);
  }, [date]);

  useEffect(() => {
    dayOffFact();
  }, []);

  const dayOffFact = async () => {
    const res = await dayOffApi.getAll();
    const dataDay = res.data.elements;
    setDayOff(dataDay);
    const resEvery = await offEveryApi.getAll();
    setEveryOff(resEvery.data.elements[0].day);
  };
  useEffect(() => {
    const decodedStringAtoB = atob(paymentResponse);
    encodeJWT(decodedStringAtoB);
  }, [merchantID]);

  const encodeJWT = (data) => {
    const toptab = {
      invoiceNo: data.substring(28, 42),
      merchantID: merchantID,
      locale: "th",
    };
    const jwt = sign(toptab, secret);
    checkPayment(jwt);
  };

  const checkPayment = async (token) => {
    const res = await axios({
      method: "post",
      url: "https://pgw.2c2p.com/payment/4.1/paymentInquiry",
      // url: "https://sandbox-pgw.2c2p.com/payment/4.1/paymentInquiry",
      data: {
        payload: token,
      },
    });
    if (res) {
      const decoded = jwt_decode(res.data.payload);
      if (decoded.respDesc !== "Success") {
        alertSweet("จ่ายเงินไม่สำเร็จ", "ดำเนินการจองห้องไม่สำเร็จ", "error");
        navigate(`/payment?invoiceNo=${invoiceNo}`);
      } else {
        alertSweet("จ่ายเงินสำเร็จ", "ดำเนินการจองห้องสำเร็จ", "success");
        navigate(`/payment?invoiceNo=${decoded.invoiceNo}`);
      }
    }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchAmount();
  }, [time, peopleAmount[0], discount]);

  const fetchAmount = async () => {
    const totalPrice = peopleAmount[0].price * time.length;
    if (discount.length > 0) {
      const dis = discount[0].promotion;
      const discountPromo = dis / 100;
      const totalAfterDiscount = totalPrice * discountPromo;
      const final = totalPrice - totalAfterDiscount;
      setAmount(final.toFixed(2));
    } else {
      setAmount(totalPrice.toFixed(2));
    }
  };

  const fetchData = async () => {
    const resRoom = await apiRoom.getRoom(roomId);
    setData(resRoom.data);
    setPeopleAmount([
      {
        value: 1,
        label: "2 people",
        price: resRoom.data.twoPeople,
      },
    ]);
    setOptions([
      {
        value: 1,
        label: "2 people",
        price: resRoom.data.twoPeople,
      },
      {
        value: 2,
        label: "3 people",
        price: resRoom.data.threePeople,
      },
      {
        value: 3,
        label: "4 people",
        price: resRoom.data.fourPeople,
      },
      {
        value: 4,
        label: "5 people",
        price: resRoom.data.fivePeople,
      },
      {
        value: 5,
        label: "6 people",
        price: resRoom.data.sixPeople,
      },
    ]);
    const resTime = await apiRoom.getTime();
    const resBook = await apiBook.getAll();
    setBooking(resBook.data.elements ? resBook.data.elements : []);
    setTimeShow(
      resTime.data.elements.map((r) => ({
        date: r.date,
        id: r.id,
        status: r.status,
        time: r.time,
        price: resRoom.data.price,
      }))
    );
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addTime = (id, timeSelect, price) => {
    // if (time.length === 0 || time[0].id === id) {
    let data = [];

    data.push(...time);
    onSelectTime(data, id, timeSelect, price);
  };

  const onSelectTime = (data, id, timeSelect, price) => {
    let allTime = data;
    if (allTime.filter((r) => r.id === id).length > 0) {
      const unTime = allTime.filter((r) => r.id !== id);
      allTime = unTime;
    } else {
      allTime.push({
        id: id,
        value: timeSelect,
        price: price,
      });
    }
    setTime(allTime);
  };

  const onWarning = () => {
    Swal.fire({
      // title: "คำเตือนก่อนจ่ายเงิน",
      // text: "after you complete your credit card payment, you will see page show \"Transaction is successful\" Be sure to press the \"Back to merchant\" button to finish the process",
      // imageUrl: lastWarnning,
      html:
        '<div style="display: flex; justify-content: center;">after <p style="color: #7066e0; font-weight: bold; margin-left: 5px;">pressing the "Submit the payment" button</p></div>' +
        // "<img width='100%' src='https://www.escaperoombangkok.com/lastWarnning.png'>" +
        'after you complete your credit card payment, you will see page show "Transaction is successful" Be sure to <p style="color: #056170; font-weight: bold;">press the "Back to merchant" button to finish the process</p>',
      confirmButtonText: "Submit the payment",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        onGenLink();
      } else if (result.isDenied) {
        Swal.fire("Not Working", "", "info");
      }
    });
  };
  const onGenLink = async () => {
    const formData = new FormData();
    formData.append("roomId", roomId);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName != null ? lastName : "null");
    formData.append("nickName", nickName != null ? nickName : "null");
    formData.append("email", email);
    formData.append("dateTime", format(new Date(date), "dd/MM/yyyy"));
    formData.append("tel", phone);
    formData.append("description", data.name);
    formData.append("peopleAmount", peopleAmount[0].value + 1);
    formData.append("amount", amount);
    for (let i = 0; i < time.length; i++) {
      formData.append(`dateTimeDTOList[${i}].dateTimeId`, time[i].id);
    }
    // const res = await api.testBook(formData);
    const res = await api.genLink(formData);
    if (res.data[0].free === true) {
      Swal.fire("Done!!", "successful payment", "success");
      navigate("/home");
    } else {
      window.location.replace(res.data[0].webPaymentUrl.replaceAll('"', ""));
    }
  };

  const onSetPeople = (select) => {
    setPeopleAmount([select]);
  };
  const onChangeNumber = (event) => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === "" || re.test(event.target.value)) {
      setPhone(event.target.value);
    }
  };

  const searchPromotion = async (e) => {
    const res = await apiPromotion.getAll();
    const resData = res.data.elements;
    if (resData.filter((r) => r.promotionName === e).length > 0) {
      setDiscount(resData.filter((r) => r.promotionName === e));
    } else if (e === "") {
      setDiscount("");
    } else {
      alertSweet("ไม่มีโปรโมชั่นโค้ดนี้", "", "error");
    }
  };
  // if (payLink) return <Navigate to={`/${payLink}`} replace={true} />;
  return (
    <div>
      <Container>
        <Card className="mt-5">
          <ModalCheck
            show={show}
            title="ยืนยันการชำระเงิน"
            detail="ยืนยัน วันที่ และ เวลา ที่เลือกหากชำระเงินไม่สามารถแก้ไขได้"
            handleClose={() => handleClose()}
          />
          <div className="mt-5 justify-content-center">
            <Row>
              <Col md={3}>
                <Image width="100%" src={data.imageUrl} />
              </Col>
              <Col style={{ textAlign: "left" }}>
                <div>
                  <h3>{data.name}</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {data.description}
                  </p>
                </div>
                <div className="mt-4">
                  <Row>
                    <Col md={4} className="text-center mt-3">
                      <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        inline
                        minDate={new Date()}
                      />
                      <br />
                      <Form.Group
                        className="mb-3 text-left"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Promotion Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Promotion Code"
                          // value={promotionName}
                          onBlur={(e) => searchPromotion(e.target.value)}
                        />
                        <br />
                        <Button>submit</Button>
                      </Form.Group>
                    </Col>
                    <Col className="text-center">
                      <div className="p-2 text-center-or-left">
                        {timeShow
                          .sort((a, b) => (a.time > b.time ? 1 : -1))
                          .map((r) => (
                            <Button
                              onClick={() => addTime(r.id, r.time, r.price)}
                              disabled={
                                booking
                                  .filter(
                                    (a) =>
                                      a.dateTime ===
                                      format(new Date(date), "dd/MM/yyyy")
                                  )
                                  .filter((a) => a.statusPayment === "complete")
                                  .filter(
                                    (a) =>
                                      a.dateTimeDTOList[0].dateTimeId === r.id
                                  )
                                  .filter((a) => a.roomId === roomId).length >
                                  0 ||
                                dayOff?.filter(
                                  (d) =>
                                    format(new Date(d.date), "dd/MM/yyyy") ===
                                    format(new Date(date), "dd/MM/yyyy")
                                ).length > 0 ||
                                everyOff === format(new Date(date), "EEEE")
                              }
                              className={
                                time.filter((el) => el.id === r.id).length > 0
                                  ? "m-2 btn-green-color"
                                  : "m-2"
                              }
                              style={
                                booking
                                  .filter(
                                    (a) =>
                                      a.dateTime ===
                                      format(new Date(date), "dd/MM/yyyy")
                                  )
                                  .filter((a) => a.statusPayment === "complete")
                                  .filter(
                                    (a) =>
                                      a.dateTimeDTOList[0].dateTimeId === r.id
                                  )
                                  .filter((a) => a.roomId === roomId).length > 0
                                  ? {
                                      backgroundColor: "#d5d5d5",
                                      borderColor: "#d5d5d5",
                                      // textDecoration: "line-through #0d6efd",
                                    }
                                  : null
                              }
                            >
                              {r.time}
                            </Button>
                          ))}
                      </div>
                      <div className="text-left p-3">
                        <p style={{ marginBottom: 0 }}>
                          Date: {format(date, "dd-MM-yyyy")}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          Time:{" "}
                          {time.length > 0
                            ? time
                                .sort((a, b) => (a.id > b.id ? 1 : -1))
                                .map((v) => v.value + ", ")
                            : "Time hasn't been chosen yet."}
                        </p>
                        <p style={{ marginBottom: 0 }}>Price: {amount} Baht</p>
                      </div>
                      <div className="text-left">
                        <Form className="p-3">
                          <Form.Group
                            className="mb-3 text-left"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>number of people</Form.Label>
                            <Select
                              options={
                                data.categoryName === "escape room"
                                  ? options.filter((r) => r.value !== 5)
                                  : options
                              }
                              value={peopleAmount}
                              onChange={(select) => onSetPeople(select)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3 text-left"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                onChange={(e) => setFirstName(e.target.value)}
                                required // ต้องการให้กรอกข้อมูล
                                isInvalid={firstName.trim() === ''} // ตรวจสอบว่าช่องว่างหรือไม่
                            />
                            <Form.Control.Feedback type="invalid" style={{ fontSize: '10px', color: '#dc3545' }}>
                              *Please Enter Name.
                            </Form.Control.Feedback>
                          </Form.Group>
                          {/*<Form.Group*/}
                          {/*  className="mb-3 text-left"*/}
                          {/*  controlId="formBasicEmail"*/}
                          {/*>*/}
                          {/*  <Form.Label>Last Name</Form.Label>*/}
                          {/*  <Form.Control*/}
                          {/*    type="text"*/}
                          {/*    placeholder="Enter Last Name"*/}
                          {/*    onChange={(e) => setLastName(e.target.value)}*/}
                          {/*  />*/}
                          {/*</Form.Group>*/}
                          {/*<Form.Group*/}
                          {/*  className="mb-3 text-left"*/}
                          {/*  controlId="formBasicEmail"*/}
                          {/*>*/}
                          {/*  <Form.Label>Nickname</Form.Label>*/}
                          {/*  <Form.Control*/}
                          {/*    type="text"*/}
                          {/*    placeholder="Enter Nickname"*/}
                          {/*    onChange={(e) => setNickName(e.target.value)}*/}
                          {/*  />*/}
                          {/*</Form.Group>*/}
                          <Form.Group
                            className="mb-3 text-left"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter E-mail"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3 text-left"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              value={phone}
                              type="text"
                              name="phone"
                              placeholder="Enter Phone"
                              onChange={(e) => onChangeNumber(e)}
                              required // ต้องการให้กรอกข้อมูล
                              isInvalid={phone.trim() === ''} // ตรวจสอบว่าช่องว่างหรือไม่
                              maxLength={10}
                            />
                            <Form.Control.Feedback type="invalid" style={{ fontSize: '10px', color: '#dc3545' }}>
                              *Please Enter Phone.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="p-2">
                        <Button
                          className="m-2"
                          style={{ width: "40%" }}
                          onClick={() => onWarning()}
                          // onClick={() => handleShow()}
                          disabled={time.length === 0}
                        >
                          Payment
                        </Button>
                        <Link
                          to={{
                            pathname: "/home",
                          }}
                        >
                          <Button
                            className="m-2"
                            variant="outline-secondary"
                            style={{ width: "40%" }}
                          >
                            Back
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Container>
    </div>
  );
}
