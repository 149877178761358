import React, { useEffect, useState } from "react";
import { Card, Container, Button, Col, Row, Form } from "react-bootstrap";
import "../../../../../scss/roomList.scss";
import Pro1Page from "./pro1";
import Pro2Page from "./pro2";
import Pro3Page from "./pro3";
import Pro4Page from "./pro4";
import Pro5Page from "./pro5";
import Pro6Page from "./pro6";

const PhotoPromotionPage = () => {
  const [review, setReview] = useState("1");

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <Form.Select
            className="mt-2"
            onChange={(e) => setReview(e.target.value)}
          >
            <option value="1">รูปที่ 1</option>
            <option value="2">รูปที่ 2</option>
            <option value="3">รูปที่ 3</option>
            <option value="4">รูปที่ 4</option>
            <option value="5">รูปที่ 5</option>
            <option value="6">รูปที่ 6</option>
          </Form.Select>
          {review === "1" ? (
            <Pro1Page />
          ) : review === "2" ? (
            <Pro2Page />
          ) : review === "3" ? (
            <Pro3Page />
          ) : review === "4" ? (
            <Pro4Page />
          ) : review === "5" ? (
            <Pro5Page />
          ) : (
            <Pro6Page />
          )}
        </div>
      </Container>
    </div>
  );
};

export default PhotoPromotionPage;
